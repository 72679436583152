@import "../../styles/mainStyles.scss";

.section {
  position: relative;
  background: $primary-gradient;
  overflow: hidden;
}

.container {
  position: relative;
  flex-direction: row;
  width: stretch;
  justify-content: space-between;
  gap: 2.5rem;

  @include m {
    padding-top: 2rem;
    padding-bottom: 2rem;
    flex-direction: column;
  }
  @include s {
    gap: 1.5rem;
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  @include xs {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  max-height: 50rem;

  &.scrollable {
    overflow-y: scroll;
    @include m {
      overflow: hidden;
      max-height: unset;
    }
    &::-webkit-scrollbar {
      display: none;
    }
  }

  @include m {
    overflow: hidden;
    max-height: unset;
  }
  &::-webkit-scrollbar {
    display: none;
  }

  .first {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 3rem;
    width: 55%;
    position: sticky;
    top: 0;
    left: 0;
    transition: .3s;

    &.hidden {
      transform: translateY(30%);
    }
    &.animate {
      animation-name: visible;
      animation-duration: .7s;
      animation-timing-function: linear;
      animation-fill-mode: forwards;
    }

    @include m {
      width: stretch;
    }

    .title {
      @include LightFont;
      font-size: 2.5rem;
      font-style: normal;
      font-weight: 300;
      line-height: 104%; /* 2.6rem */
      text-align: left;
      color: $ternary-color;
      width: 50%;

      strong {
        @include RegularFont;
      }

      @include m {
        width: 90%;
        font-size: 2.25rem;
      }
      @include s {
        width: 100%;
        font-size: 2rem;
      }
      @include xs {
        font-size: 1.875rem;
      }
    }
    img {
      margin-left: -8rem;

      @include l {
        margin-left: -10rem;
      }
      @include m {
        margin-left: -2rem;
      }
      @include s {
        margin: 0 -4rem 0 -3rem;
      }
      @include xs {
        margin: 0 -6rem 0 -2rem;
      }
    }
  }
  .second {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    gap: 2.5rem;
    height: 100%;
    padding: 5rem 0;

    @include m {
      width: stretch;
      padding: 2rem 0;
      gap: 2rem;
    }
    @include s {
      padding: 1rem 0;
      gap: 1rem;
    }

    .value {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 1rem;
      padding: .5rem 0;
      color: $ternary-color;

      &:first-child {
        padding-top: 5rem;
        @include m {
          padding: .5rem 0;
        }
      }
      &:last-child {
        padding-bottom: 20rem;
        @include m {
          padding: .5rem 0;
        }
      }

      &:last-of-type {
        .title {
          width: 110%;
        }
      }

      .title {
        font-size: 2.5rem;
        font-style: normal;
        font-weight: 400;
        line-height: 104%;
        color: $ternary-color;
        @include s {
          font-size: 2rem;
        }
        @include xs {
          font-size: 1.875rem;
        }
      }
      .subtitle {
        @include RegularFont;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 400;
        line-height: 111%;
        color: $ternary-color;
        @include s {
          font-size: 1.25rem;
        }
        @include xs {
          font-size: 1.125rem;
        }
      }
    }
  }
}

@keyframes visible {
  0% {
    transform: translateY(30%);
    opacity: 0.5;
  }
  50% {
    transform: translateY(15%);
    opacity: 0.7;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}