@import "../../styles/mainStyles.scss";

.section {
  position: relative;
  overflow: hidden;

  &::after {
    content: '';
    position: absolute;
    background: $primary-gradient;
    z-index: -1;
    top: 55%;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.container {
  width: stretch;
  gap: 3rem;
  padding-top: 2rem;
  padding-bottom: 2rem;

  @include s {
    gap: 2rem;
  }
  @include xs {
    gap: 1rem;
  }

  .header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: stretch;
    gap: 1rem;

    @include s {
      justify-content: flex-start;
    }

    > span {
      &:first-of-type {
        @include LightFont;
        font-size: 2rem;
        font-style: normal;
        font-weight: 300;
        line-height: 104%;

        @include s {
          font-size: 1.75rem;
        }
        @include xs {
          font-size: 1.5rem;
        }
      }
      &:last-of-type {
        font-size: 1.25rem;
        font-weight: 400;
        line-height: 104%;
        text-align: left;
        @include s {
          font-size: 1rem;
        }
      }
    }
  }
  .members {
    display: grid;
    grid-template-areas: "a a a";
    gap: 1rem;
    grid-auto-rows: min-content;
    padding: 0 2rem 2rem 1rem;
    margin: 0 -2rem 0 -1rem;

    @include m {
      overflow-x: scroll;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    .card {
      flex-direction: column;
      justify-content: flex-start;
      border-radius: .5rem;
      min-width: 400px;

      .preview {
        height: 25rem;
        overflow: hidden;
        position: relative;
        border-radius: .625rem;
        img {
          border-radius: .625rem;
          width: 100%;
          height: 100%;
          object-fit: cover;
          min-width: 400px;
        }

        .name {
          position: absolute;
          left: 50%;
          -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
          bottom: 2.5rem;
          @include BoldFont;
          font-size: 1.5rem;
          font-weight: 700;
          line-height: 100%;
          color: $bg-color;
          width: 100%;
          text-align: center;
        }
      }
      .text {
        display: inherit;
        flex-direction: column;
        gap: 1rem;
        padding: .5rem 1.5rem;
        color: $bg-color;

        .description {
          @include LightFont;
          font-size: .875rem;
          font-style: normal;
          font-weight: 400;
          line-height: 105%; /* 1.05rem */

          @include s {
            font-size: .875rem;
          }
        }
      }
    }
  }
}