// Rubik-Light
@font-face {
  font-family: 'LightFont';
  src: url('../assets/fonts/Rubik-Light.ttf');
}
@mixin LightFont {
  font-family: LightFont;
}
// Rubik-Regular
@font-face {
  font-family: 'RegularFont';
  src: url('../assets/fonts/Rubik-Regular.ttf');
}
@mixin RegularFont {
  font-family: RegularFont;
}
// Rubik-Medium
@font-face {
  font-family: 'MediumFont';
  src: url('../assets/fonts/Rubik-Medium.ttf');
}
@mixin MediumFont {
  font-family: MediumFont;
}
// Rubik-Bold
@font-face {
  font-family: 'BoldFont';
  src: url('../assets/fonts/Rubik-Bold.ttf');
}
@mixin BoldFont {
  font-family: BoldFont;
}
// Inter-Regular
@font-face {
  font-family: 'InterRegular';
  src: url('../assets/fonts/Inter-Regular.ttf');
}
@mixin InterRegular {
  font-family: InterRegular;
}