@import '../../../styles/mainStyles.scss';

.menu {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  flex-grow: 1;
  width: 100%;
  height: 100%;
  background: $white;
  overflow: hidden;
  transition: 1s;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .close {
    width: 1.25rem;
    height: 1.25rem;
    padding-bottom: 3rem;

    &:hover {
      path {
        fill: $gray-fifty;
      }
      cursor: pointer;
      opacity: .8;
    }
  }

  .option {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1.5rem 0;
    width: 100%;
    max-width: 21rem;

    border-top: 1px solid $gray-fifty;

    @include MediumFont;
    font-weight: 500;
    font-size: 1rem;
    line-height: 104%;

    &:last-child {
      border-bottom: 1px solid $gray-fifty;
    }

    &:hover {
      cursor: pointer;
     color: $gray-fifty;
    }
  }
}