@import "../../styles/mainStyles.scss";

.section {
  position: relative;
  background-image: url('../../assets/images/banner-background.jpg');
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  flex-direction: column;

  height: 765px;
  @include m {
    height: 725px;
    text-align: center;
  }
  @include s {
    height: 700px;
    background-position: top left;
  }
}
.banner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: stretch;
  background: $primary-gradient;
  padding: 1.75rem 1.5rem;

  @include xs {
    padding: 1.5rem 1.5rem;
  }
  > span {
    font-size: 1.875rem;
    font-weight: 400;
    line-height: 104%;
    text-transform: uppercase;
    color: $bg-color;
    text-align: center;

    @include s {
      font-size: 1.5rem;
    }
    @include xs {
      font-size: 1.25rem;
    }
  }
}

.container {
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  gap: 1.75rem;
  width: stretch;
  height: 100%;
  padding-bottom: 7rem;

  @include s {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    gap: 1.5rem;
    padding-bottom: 5rem;
  }
  @include xs {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  section {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    > span {
      text-transform: uppercase;
      @include RegularFont;
      font-weight: 400;
      line-height: 110%;
      text-align: left;
      &:first-of-type {
        font-size: 3.75rem;
        width: 50%;
        color: $primary-color;

        @include m {
          width: 70%;
          font-size: 3.5rem;
        }
        @include s {
          width: 90%;
          font-size: 3rem;
        }
        @include xs {
          font-size: 2.5rem;
          width: 85%;
        }
      }

      &:last-of-type {
        font-size: 1.875rem;
        color: $bg-color;

        @include m {
          font-size: 1.75rem;
        }
        @include s {
          font-size: 1.5rem;
        }

      }
    }
  }

  .footer {
    @include LightFont;
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 110%;
    text-align: right;
    color: $bg-color;
    width: 30%;

    @include m {
      width: 50%;
    }
    @include s {
      font-size: 1rem;
      text-align: left;
      width: 85%;
    }
  }
}

.button {
  background: $blue-hub;
  width: fit-content;
}
.linkButton {
  text-decoration: none;
  color: white;
  padding: 0 1.25rem;
}