@import "../../styles/mainStyles.scss";

.section {
  background: url('../../assets/images/ecosystem-background.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  border-bottom-right-radius: 6.25rem;

  @include m {
    border-bottom-right-radius: 4rem;
  }
}
.container {
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  padding: 3rem 1.5rem 4rem;

  @include m {
    flex-direction: column;

    svg {
      transform: scale(.9);
    }
  }

  > section {
    &:first-of-type {
      width: 32%;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      color: $bg-color;
      text-align: left;
      @include m {
        width: 100%;
      }
      > span {
        &:first-of-type {
          font-size: 2.75rem;
          font-weight: 400;
          line-height: 104%;
          @include s {
            font-size: 2.25rem;
          }
        }
        &:last-of-type {
          @include LightFont;
          font-size: 1.25rem;
          font-weight: 400;
          line-height: 104%;
          @include s {
            font-size: 1rem;
          }
        }
      }

    }
    &:last-of-type {
      width: 40%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: flex-start;
      justify-content: flex-end;
      gap: 1rem;
      color: $bg-color;
      text-align: left;
      @include m {
        width: 100%;
        justify-content: center;
      }
      > p {
        display: flex;
        flex-direction: column;
        gap: .25rem;
        width: calc(50% - 1rem);

        &:nth-of-type(2),
        &:nth-of-type(4),
        &:nth-of-type(6) {
          color: $light-fifty;
        }

        > span {
          line-height: 104%;
          text-align: left;
          &:first-of-type {
            @include MediumFont;
            font-size: 1.875rem;
            font-weight: 500;
            text-transform: uppercase;
            padding-bottom: .25rem;
            @include s {
              font-size: 1.25rem;
            }
          }
          &:nth-of-type(2) {
            @include LightFont;
            font-size: 1.25rem;
            font-weight: 400;
            text-transform: uppercase;
            @include s {
              font-size: 1rem;
            }
          }
          &:last-of-type {
            @include LightFont;
            font-size: .875rem;
            font-weight: 400;
          }
        }

      }
    }
  }
}