@import "../../styles/mainStyles.scss";

.section {
  position: relative;
  background: #55535338;
}

.container {
  flex-direction: row;
  width: stretch;
  justify-content: space-between;
  gap: 3.5rem;
  padding-top: 5rem;
  padding-bottom: 5rem;

  @include m {
    flex-direction: column;
    gap: 1.5rem;
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  @include xs {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .header {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 25%;

    @include m {
      width: 80%;
    }
    @include s {
      width: 90%;
    }
    @include xs {
      width: 100%;
    }

    .title {
      @include RegularFont;
      font-size: 2.5rem;
      font-weight: 400;
      line-height: 100%; /* 2.6rem */
      text-align: left;

      @include m {
        font-size: 2.25rem;
      }
      @include s {
        font-size: 2rem;
      }
      @include xs {
        font-size: 1.875rem;
      }
    }
    .subtitle {
      @include RegularFont;
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 300;
      line-height: 120%; /* 1.2rem */

      @include s {
        font-size: 1rem;
      }
    }
  }
  .cards {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1rem;
    width: 75%;

    .card {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 1.5rem;
      flex-grow: 1;
      width: calc(25% - 1rem);
      border-bottom: 2px solid $blue-hub;
      padding: .5rem 0 2rem;

      > span {
        font-size: 1.25rem;
        text-align: left;
        line-height: 100%;
        @include s {
          font-size: 1rem;
        }
        @include xs {
          font-size: .875rem;
        }
        &:first-of-type {
          @include RegularFont;
          font-weight: 600;
        }
        &:last-of-type {
          @include RegularFont;
          font-weight: 400;
        }
      }

      @include m {
        width: calc(50% - 1rem);
        padding: .5rem 0 .75rem;
      }
    }

    @include m {
      gap: 1.5rem;
      width: 100%;
    }
  }
}
