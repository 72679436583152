$lightness-sensitive: 50;

$primary-color: #FF8A00;
$green-hub: #00C271;
$blue-hub: #00A3FF;
$secondary-color: #310C6C;
$ternary-color: #F1F1F1;
$primary-gradient: linear-gradient(270deg, #006299 0%, #007444 100%);
$purple-blue-gradient: linear-gradient(90deg, #D80587 0%, #310C6C 100%);
$real-estate-gradient: linear-gradient(90deg, #6BB2FF 0%, #68A8FE 8%, #5B79FB 50%, #535BF9 82%, #5051F9 100%);

$primary-button-color: $primary-color;
$secondary-button-color: $primary-color;
$footer-bg-color: $primary-gradient;
$icon-color: $primary-color;
