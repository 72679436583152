@import "../../styles/mainStyles.scss";

.section {
  position: relative;
  background: #55535338;
}

.container {
  flex-direction: row;
  width: stretch;
  justify-content: space-between;
  gap: 3.5rem;
  padding-top: 5rem;
  padding-bottom: 5rem;

  @include m {
    flex-direction: column;
    gap: 1rem;
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  @include xs {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .header {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 25%;

    @include m {
      gap: .5rem;
      width: 80%;
    }
    @include s {
      width: 90%;
    }
    @include xs {
      width: 100%;
    }

    .title {
      @include RegularFont;
      font-size: 2.5rem;
      font-weight: 400;
      line-height: 100%; /* 2.6rem */
      text-align: left;

      @include m {
        font-size: 2.25rem;
      }
      @include s {
        font-size: 2rem;
      }
      @include xs {
        font-size: 1.875rem;
      }
    }

    ul {
      li {
        @include RegularFont;
        font-size: 1.25rem;
        font-weight: 400;
        line-height: 104%;
        text-align: left;
        @include s {
          font-size: 1rem;
        }
      }
    }
  }
  section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    // gap: .25rem;
    width: 75%;

    > span {
      @include RegularFont;
      font-size: 1.25rem;
      font-weight: 400;
      line-height: 100%;
      text-align: left;
      @include s {
        font-size: 1rem;
      }
    }
    @include m {
      width: 100%;
    }
  }
}
