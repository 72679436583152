@import '../../styles/mainStyles.scss';

.section {
  background: url('../../assets/images/collaborative-space-background.jpg');
  background-position: top right;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  &::after {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #55535338;
  }

  border-bottom-right-radius: 6.25rem;

  @include m {
    border-bottom-right-radius: 4rem;
  }
}
.container {
  width: stretch;
  align-items: center;
  justify-content: space-between;
  padding-top: 4rem;
  padding-bottom: 4rem;


  @include m {
    flex-direction: column;
    align-items: flex-start;
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  > section {
    width: 50%;
    @include m {
      width: 100%;
    }
    &:first-of-type {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
      color: $bg-color;
      @include m {
        padding: 0 0 1rem;
      }
      > span {
        font-weight: 400;
        line-height: 104%;
        text-align: left;
        &:first-of-type {
          @include RegularFont;
          font-size: 2.75rem;
          @include s {
            font-size: 2.25rem;
          }
          @include xs {
            font-size: 2rem;
            width: 90%;
          }
        }
        &:last-of-type {
          @include RegularFont;
          font-size: 1.25rem;
          @include s {
            font-size: 1rem;
          }
        }
      }
      button {
        width: fit-content;
        padding: .25rem 2rem;

        a {
          text-decoration: none;
          color: $bg-color;
        }
      }
    }
    &:last-of-type {
      display: flex;
      justify-content: center;
      align-items: center;

      @include m {
        img {
          transform: scale(.8);
        }
      }
    }
  }
}