@import '../../styles/mainStyles.scss';

.section {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  display: block;
}

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  gap: 3.5rem;
  background: transparent;

  @include s {
    gap: 2.5rem;
    padding: 2rem 1rem;
  }
  @include xs {
    gap: 1.5rem;
  }

  .logo {
    transition: $animation-timing-default;
     &:hover {
      cursor: pointer;
      transform: scale(1.02);
     }

    @include m {
      max-width: 12.5rem;
    }
    @include xs {
      max-height: 1.9rem;
      max-width: 10rem;
    }
  }
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 3rem;
  margin-left: auto;

  .navItem {
    @include RegularFont;
    font-size: .75rem;
    font-weight: 500;
    color: $ternary-color;
    text-transform: uppercase;
    &:hover {
      cursor: pointer;
    }

    @include m {
      display: none;
    }
  }
  .linkButton {
    text-decoration: none;
  }
  .platformButton {
    border-radius: .5rem;
    padding: 0 3rem;
    @include xs {
      min-height: unset;
      height: 1.875rem;
      padding: 0 1.5rem;
    }
    &:hover {
      cursor: pointer;
      opacity: .8;
    }
  }
  .language {
    display: flex;
    justify-self: center;
    align-items: center;
    gap: .5rem;

    @include s {
      display: none;
    }

    > span {
      @include RegularFont;
      font-size: .75rem;
      font-weight: 500;
      color: $ternary-color;
      text-transform: uppercase;

      &.selected {
        color: $primary-color !important;
      }

      &:hover {
        cursor: pointer;
        color: $light;
      }
    }
  }

  .language {
    display: none;
  }
}