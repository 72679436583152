@import "../../styles/mainStyles.scss";

.section {
  position: relative;
  overflow: hidden;
  flex-direction: column;
}

.container {
  width: stretch;
  gap: 2rem;
  padding-top: 3rem;
  padding-bottom: 2rem;
  padding-right: 0;

  @include m {
    gap: 2rem;
  }
  @include s {
    gap: 1.5rem;
  }
  @include xs {
    gap: 1rem;
  }

  .header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: stretch;

    @include m {
      justify-content: flex-start;
    }
    .title {
      @include RegularFont;
      font-size: 2.75rem;
      line-height: 100%;
      text-align: left;

      @include m {
        font-size: 2.5rem;
      }
      @include xs {
        font-size: 2rem;
      }
      @include xs {
        font-size: 1.75rem;
      }
    }
  }
  section {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    overflow: visible;
    overflow-x: auto;
    @include scrollBar;
    &::-webkit-scrollbar-track:horizontal {
      margin: 0 .75rem;
    }
    // padding: 0 0rem 0 0rem;
    // margin: 0 -0rem 0 0rem;
  }
  .partners {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    padding: 0 2rem 2rem 1rem;
    margin: 0 -2rem 0 -1rem;
    justify-content: flex-start;
    // width: 100%;
    min-width: 1370px;

    img {
      object-fit: contain;
      min-width: fit-content;
    }

    // @include l {
    //   flex-wrap: nowrap;
    //   gap: 3rem;
    //   overflow-x: scroll;
    //   grid-template-areas: unset;
    //   &::-webkit-scrollbar {
    //     display: none;
    //   }
    // }
    @include m {
      padding-bottom: 5rem;
    }
    @include s {
      padding-bottom: 4rem;
    }
    @include xs {
      padding-bottom: 3rem;
    }
  }
}

.sliderBanner {
  background: $purple-blue-gradient;
}
.slider {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: stretch !important;
  overflow: hidden !important;
  padding: .5rem 0;
  transition-timing-function: linear !important;
  .swiper-wrapper {
    transition-timing-function: linear;
  }
}
.swiperSlide {
  flex-shrink: 0;
  transition: 1s;
  width: min-content !important;
  transition-timing-function: linear !important;
}
.labelSlide {
  display: flex;
  gap: 2rem;
  justify-content: center;
  align-items: center;
  padding: 0 1.5rem 0 0;
  width: min-content !important;

  span {
    @include LightFont;
    color: $ternary-color;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 400;
    line-height: 111%; /* 1.665rem */
    white-space: nowrap;
  }
}