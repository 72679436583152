@import '../../styles/mainStyles.scss';

.section {
  background: url('../../assets/images/viabilizador-background.jpg');
  background-position: center left;
  background-repeat: no-repeat;
  background-size: cover;
}
.container {
  width: stretch;
  align-items: flex-start;
  justify-content: space-between;

  @include m {
    flex-direction: column;
    align-items: flex-start;
  }

  > section {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    width: 45%;
    padding: 6rem 0;
    color: $bg-color;
    @include m {
      padding: 2.5rem 0;
      width: 100%;
    }
    > span {
      font-weight: 400;
      line-height: 104%;
      text-align: left;
      &:first-of-type {
        @include RegularFont;
        font-size: 2.75rem;
        @include s {
          font-size: 2.25rem;
        }
        @include xs {
          font-size: 2rem;
          width: 90%;
        }
      }
      &:last-of-type {
        @include RegularFont;
        font-size: 1.25rem;
        @include s {
          font-size: 1rem;
        }
      }
    }
    button {
      width: fit-content;
      padding: .25rem 2rem;

      a {
        text-decoration: none;
        color: $bg-color;
      }
    }
  }
}