@import '../../styles/mainStyles.scss';

.section {
  margin-top: -3rem;
  background: transparent;
}
.container {
  width: stretch;
  align-items: flex-start;
  justify-content: space-between;

  @include m {
    flex-direction: column;
    align-items: flex-start;
  }

  > section {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 40%;
    padding: 6rem 0;

    @include m {
      padding: 2.5rem 0;
      width: 100%;
    }
    > img {
      max-width: 389px;

      @include xs {
        height: 100%;
        width: 100%;
      }
    }
    > span {
      font-weight: 400;
      line-height: 104%;
      text-align: left;
      &:first-of-type {
        @include RegularFont;
        font-size: 2.75rem;
        @include s {
          font-size: 2.25rem;
        }
        @include xs {
          font-size: 2rem;
        }
      }
      &:nth-of-type(2) {
        @include RegularFont;
        font-size: 1.25rem;
        @include s {
          font-size: 1rem;
        }
      }
      &:last-of-type {
        padding-top: .5rem;
        @include MediumFont;
        font-weight: 700;
        font-size: 1.25rem;
        text-transform: uppercase;
        @include s {
          font-size: 1rem;
        }
      }
    }
  }
}