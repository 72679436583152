@import './brand-colors.scss';

$neutrals1: #141416;
$neutrals2: #23262f;
$neutrals3: #353945;
$neutrals4: #777e91;
$neutrals5: #b1b5c3;
$neutrals6: #e6e8ec;

$purple: #D80587;
$error: #D80505;
$red: #D8052B;
$black: #000;
$white: #fff;
$light: #FDF4F4;
$light-fifty: rgba(253, 244, 244, 0.5);
$light-twenty-five: rgba(253, 244, 244, 0.25);
$gray: #4B4B4A;
$gray-fifty: rgba(75, 75, 74, 0.5);
$gray-dark: #3A383C;
$dark: #131517;
$dark-medium: #1E1F25;
$dark-light: #435145;
$blue-light: #0775A4;
$blue: #5051F9;
$blue-dark: #18293A;
$green: #59D939;
$success: #04C58B;

$shadow-color: rgba(0, 0, 0, 0.1);
$default-lightness-sensitive: $lightness-sensitive;
$default-icon-color: $icon-color;

:root, :root.light {
  --bg-color: #fff;
  --text-color: #131517;
  background: #fff;
  color: #131517;

  .footer-background {
    background: $secondary-color;
  }
}
:root.dark {
  --bg-color: #1E1F25;
  --text-color: $ternary-color;
  background: #1E1F25;
  color: $ternary-color;

  .footer-background {
    background: $dark;
  }
}

$text-color: var(--text-color);
$bg-color: var(--bg-color);