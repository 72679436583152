@import '../../styles/mainStyles.scss';

.container {
  width: stretch;
  overflow: hidden;
  padding-top: 0rem;
  padding-bottom: 1rem;
  > section {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: stretch;
    overflow-x: auto;
    gap: 1rem;
    @include scrollBar;
    &::-webkit-scrollbar-track:horizontal {
      margin: 0 .75rem;
    }
    padding: 2rem 2rem 1.5rem 0rem;
    margin: 2rem -2rem 0 0rem;

    &::-webkit-scrollbar {
      display: none;
    }

    .box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      min-width: 450px;
      gap: 1rem;
      width: 50%;
      padding: 1rem 2rem;
      &:first-of-type {
        padding-left: 0;
      }
      &:last-of-type {
        background: #00A3FF33;
        border-radius: .625rem;
        border: 3px solid $blue-hub;
        position: relative;
        > span {
          position: absolute;
          top: -2rem;
          left: 50%;
          -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
          @include RegularFont;
          font-size: 1.25rem;
          font-weight: 600;
          line-height: 104%;
          color: $blue-hub;

        }
      }
    }
    .card {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 1rem;

      > span {
        @include RegularFont;
        font-size: 1.25rem;
        font-weight: 500;
        line-height: 104%;
      }
    }
  }
}