@import "../../styles/mainStyles.scss";

.section {
  position: relative;
  overflow: hidden;
  flex-direction: column;
  background: #55535338;
}

.container {
  width: stretch;
  gap: 1.5rem;
  padding-top: 4rem;
  padding-bottom: 4rem;

  @include s {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: stretch;
    gap: 1rem;

    > span {
      @include RegularFont;
      font-weight: 400;
      line-height: 104%;
      text-align: center;
      &:first-of-type {
        font-size: 1.25rem;
        @include s {
          font-size: 1rem;
        }
      }
      &:last-of-type {
        font-size: 2.75rem;
        letter-spacing: -0.02em;

        @include m {
          font-size: 2.5rem;
        }
        @include s {
          font-size: 2rem;
        }
        @include xs {
          font-size: 1.5rem;
        }
      }
    }
  }
  .socials {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5rem;

    @include m {
      gap: 4rem;
    }
    @include s {
      gap: 3rem;
    }
    @include xs {
      gap: 2rem;
    }
    a {
      text-decoration: none;
    }
  }
}